import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment_prod } from '../../../environments/environment.prod';
import { environment_test } from '../../../environments/environment.test';
import { environment_dev } from '../../../environments/environment.dev';
import { environment_us_prod } from '../../../environments/us/environment.us.prod';
import { environment_us_test } from '../../../environments/us/environment.us.test';
import { environment_us_dev } from '../../../environments/us/environment.us.dev';
import { getPxdDevEnvironmentFromSubdomain, setPxdSubdomainFromUrl } from './environment.pxd-dev';
import { logging_config } from '../../logging-config/logging-config';
import { logging_config_dev } from '../../logging-config/logging-config_dev';
import { Logger, LoggingService } from 'ionic-logging-service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})

export class EnvironmentService {

//   public isCordova: boolean = true;
    public environmentPrefix: string;
    public currentEnvironmentEndpoints: any;
    public loggingConfig = logging_config;
    private ENVIRONMENT_PREFIX_STORAGE_KEY = 'environmentPrefix';
    private logger: Logger;

    constructor(
        private loggingService: LoggingService,
        private storageService: StorageService
    )
    {
        this.logger = loggingService.getLogger("[EnvironmentService]");
        const methodName = "ctor";
        this.logger.entry(methodName);

        this.setEnvironmentPrefixFromStorage().subscribe(() => {});
    }

    public setEnvironmentPrefixFromStorage(): Observable<void> {
        this.logger.info('setEnvironmentPrefixFromStorage()');
        return new Observable((observer) => {
            this.storageService.getItemFromKeyValueTable(this.ENVIRONMENT_PREFIX_STORAGE_KEY, 'setEnvironmentPrefixFromStorage()').subscribe((environmentPrefix) => {
                console.log('environmentPrefix FROM_STORAGE', environmentPrefix);
                if (environmentPrefix) {
                    switch (environmentPrefix) {
                        case 'environment_us_dev':
                            this.currentEnvironmentEndpoints = environment_us_dev
                            this.setLogginConfig(logging_config_dev);
                        break;
                        case 'environment_us_test':
                            this.currentEnvironmentEndpoints = environment_us_test
                            this.setLogginConfig(logging_config);
                        break;
                        case 'environment_us_prod':
                            this.currentEnvironmentEndpoints = environment_us_prod
                            this.setLogginConfig(logging_config);
                        break;
                        case 'environment_dev':
                            this.currentEnvironmentEndpoints = environment_dev
                            this.setLogginConfig(logging_config_dev)
                        break;
                        case 'environment_test':
                            this.currentEnvironmentEndpoints = environment_test
                            this.setLogginConfig(logging_config_dev);
                        break;
                        case 'environment_prod':
                            this.currentEnvironmentEndpoints = environment_prod
                            // this.currentEnvironmentEndpoints = environment_test
                            this.setLogginConfig(logging_config);
                            break;
                        default:
                            // Determine if this is a printix.dev or sandbox.printix.dev environment host
                            // Don't use regex in order to include broadest platform support.
                            const pos = environmentPrefix.indexOf('environment_pxd_');
                            if (pos == 0) {
                                const printixDevSubdomain = environmentPrefix.substring('environment_pxd_'.length) + '.printix.dev';
                                this.currentEnvironmentEndpoints = getPxdDevEnvironmentFromSubdomain(printixDevSubdomain);
                                this.setLogginConfig(logging_config_dev);
                            } else {
                                this.currentEnvironmentEndpoints = environment_prod
                                this.setLogginConfig(logging_config);
                            }
                            break;
                    }
                } else {
                    this.currentEnvironmentEndpoints = environment_prod
                    environmentPrefix = 'environment_prod'
                    this.setLogginConfig(logging_config);
                }
                this.logger.info('setEnvironmentPrefixFromStorage() - environmentPrefix: ' + environmentPrefix);
                observer.next(environmentPrefix);
                observer.complete();
            });
        });
    }

    public setEnvironmentVariablesFromUrl(url): Observable<string> {
        this.logger.info('setEnvironmentVariablesFromUrl() - URL: ' + url);
        let environmentPrefix = 'environment_prod'
        return new Observable((observer) => {
            if (url.includes('.printix.dev')) {
                // Determine if this is a printix.dev or sandbox.printix.dev environment host
                // Don't use regex in order to include broadest platform support.
                const pxdSubdomain = setPxdSubdomainFromUrl(url);

                if (pxdSubdomain != '') {
                    this.currentEnvironmentEndpoints = getPxdDevEnvironmentFromSubdomain(pxdSubdomain);
                    this.setLogginConfig(logging_config_dev);
                    environmentPrefix = this.currentEnvironmentEndpoints.environmentPrefix;
                }
            } else if (url.includes('.us.')) {
                if (url.includes('.devenv.us.printix.net')) {
                    this.currentEnvironmentEndpoints = environment_us_dev;
                    this.setLogginConfig(logging_config_dev);
                    environmentPrefix = 'environment_us_dev';
                } else if (url.includes('.testenv.us.printix.net')) {
                    this.currentEnvironmentEndpoints = environment_us_test;
                    this.setLogginConfig(logging_config);
                    environmentPrefix = 'environment_us_test';
                } else {
                    this.currentEnvironmentEndpoints = environment_us_prod;
                    this.setLogginConfig(logging_config);
                    environmentPrefix = 'environment_us_prod';
                }
            } else {
                if (url.includes('.devenv.printix.net')) {
                    this.currentEnvironmentEndpoints = environment_dev;
                    this.setLogginConfig(logging_config_dev)
                    environmentPrefix = 'environment_dev';
                } else if (url.includes('.testenv.printix.net')) {
                    this.currentEnvironmentEndpoints = environment_test;
                    this.setLogginConfig(logging_config_dev);
                    environmentPrefix = 'environment_test';
                } else {
                    this.currentEnvironmentEndpoints = environment_prod;
                    this.setLogginConfig(logging_config);
                    environmentPrefix = 'environment_prod';
                }
            }

            this.storageService.addItemToKeyValueTable(this.ENVIRONMENT_PREFIX_STORAGE_KEY, environmentPrefix, 'addEnvironmentPrefixToLocalStorage()').subscribe(() => {
                this.logger.info('setEnvironmentVariablesFromUrl() - environmentPrefix: ' + environmentPrefix);
                observer.next(environmentPrefix);
                observer.complete();
            });
        });
    }

    public get getEnvironmentEndpoint() {
        if (this.currentEnvironmentEndpoints) {
            console.log('endpointApi', this.currentEnvironmentEndpoints['endpointApi']);
        }
        return this.currentEnvironmentEndpoints;
    }

    public resetEnvironmentEndpoints(): Observable<void> {
        this.logger.info('resetEnvironmentEndpoints()');
        return new Observable((observer) => {
            this.storageService.removeItemFromKeyValueTable(this.ENVIRONMENT_PREFIX_STORAGE_KEY).subscribe(() => {
                this.currentEnvironmentEndpoints = environment_prod;
                this.environmentPrefix = 'environment_prod';
                observer.next();
                observer.complete();
            });
        });
    }

    private setLogginConfig(loggingConfig) {
        this.logger.info('setLogginConfig()');
        this.loggingService.configure(loggingConfig)
        // this.loggingService.configure(logging_config_dev);
    }

    // public getEnvironmentEndpointUrl(endpointName: string): Observable<string> {
    //     this.logger.info('getEnvironmentEndpoint()');
    //     // let baseEndpoint = environment[endpointName];
    //     // console.log('baseEndpoint', baseEndpoint);
    //     console.log('endpointName', endpointName);
    //     console.log('this.environmentPrefix', this.environmentPrefix);
    //     this[endpointName] = endpointName;
    //     switch (this.environmentPrefix) {
    //         case 'environment_us_prod': {
    //             return environment_us_prod[this[endpointName]];
    //         }
    //         case 'environment_us_test': {
    //             return environment_us_test[this[endpointName]];
    //         }
    //         case 'environment_us_dev': {
    //             return environment_us_dev[this[endpointName]];
    //         }
    //         case 'environment_test': {
    //             return environment_test[this[endpointName]];
    //         }
    //         case 'environment_dev': {
    //             return environment_dev[this[endpointName]];
    //         }
    //         default: {
    //             console.log('this[endpointName]', this[endpointName]);
    //             return environment_prod[this[endpointName]];
    //         }

    //     }
    // }

    // setEnvironmentVariablesFromUrl(url) {
     // ((\b(?:\.us|\.us\.devenv|\.us\.testenv)\b)?\.printix.net)
        // if (url.match("(.[a-z]{2})?(.us|.us.devenv|.us.testenv)?.printix.net/.*")) {
        //     switch (url) {
        //         case url.match("(.[a-z]{2})?.us.devenv.printix.net/.*"): {
        //             return 'environment_us_dev';
        //         }
        //         case url.match("(.[a-z]{2})?.us.testenv.printix.net/.*"): {
        //             return 'environment_us_test';
        //         }
        //         default: {
        //             return 'environment_us_prod';
        //         }
        //     }
        // } else if (url.match("(.[a-z]{2})?(.devenv|.testenv)?.printix.net/.*")) {
        //     switch (url) {
        //         case url.match("(.[a-z]{2})?.devenv?.printix.net/.*"): {
        //             return 'environment_dev';
        //         }
        //         case url.match("(.[a-z]{2})?.testenv?.printix.net/.*"): {
        //             return 'environment_test';
        //         }
        //         default: {
        //             return 'environment_prod';
        //         }
        //     }
        // }

    // public getArrayOfEndpoints(endpointNames: Array<string>): Observable<Array<string>> {
    //     // let endPointsArray = [];
    //     return new Observable((observer) => {
    //         // endpointNames.forEach(name => {

    //         // });
    //         const endpointsArray: Array<any> = endpointNames.map((name) => {this.currentEnvironmentEndpoints[name];});
    //         observer.next(endpointsArray);
    //         observer.complete();
    //     });
    // }
}
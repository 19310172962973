import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { ErrorService } from '../error/error.service';
import { LanguageService } from '../language/language.service';
import { IWorkflow, IWorkflowActivity } from './models/workflow.model';

const DATE_FORMAT_MAP = {
  MM_DD_YYYY: '{MM_DD_YYYY}',
  MM_DD_YYYY_TIME: '{MM_DD_YYYY_TIME}',
  DD_MM_YYYY: '{DD_MM_YYYY}',
  DD_MM_YYYY_TIME: '{DD_MM_YYYY_TIME}',
  YYYY_MM_DD: '{YYYY_MM_DD}',
  YYYY_MM_DD_TIME: '{YYYY_MM_DD_TIME}',
}

const PDF_COMPATIBILITY = {
  PDF_2_0: 'PDF 2.0',
  PDF_1_7: 'PDF 1.7',
  PDF_1_6: 'PDF 1.6',
  PDF_1_5: 'PDF 1.5',
  PDF_1_4: 'PDF 1.4',
  PDF_1_3: 'PDF 1.3',
  PDF_1_2: 'PDF 1.2',
  PDF_1_1: 'PDF 1.1',
  PDF_1_0: 'PDF 1.0',
  PDFA_1B: 'PDF/A-1b',
  PDFA_2B: 'PDF/A-2b',
  PDFA_3B: 'PDF/A-3b',
  PDFA_3U: 'PDF/A-3u',
  PDFA_1A: 'PDF/A-1a',
  PDFA_2A: 'PDF/A-2a',
  PDFA_2U: 'PDF/A-2u',
  PDFA_3A: 'PDF/A-3a',
}
const IMAGE_QUALITY = {
  MIN: 'LOW',
  MEDIUM: 'MEDIUM',
  MAX: 'HIGH',
}
const ORIENTATION = {
  AUTO: 'SelectAutomatically',
  // AUTO: 'Auto',
  LANDSCAPE: 'Landscape',
  PORTRAIT: 'Portrait',
}
const PAPER_SIZE = {
  AUTO: 'SelectAutomatically',
  // AUTO: 'Auto',
  A3: 'A3',
  A4: 'A4',
  A5: 'A5',
  A6: 'A6',
  B4: 'B4',
  B5: 'B5',
  LEDGER: 'Ledger',
  LEGAL: 'Legal',
  LETTER: 'Letter',
  FOLIO: 'Folio',
  STATEMENT: 'Statement',
  EXECUTIVE: 'Executive',
}

const COLOR = {
  GREY: 'Grey',
  COLOR: 'Color',
  BLACK_AND_WHITE: 'BlackAndWhite',
}
const FILE_NAME_FORMAT = [
  'PRINTER_ID',
  'PRINTER_LOCATION',
  'PRINTER_NAME',
  'USER_NAME',
  'WORKFLOW_NAME',
]


@Injectable({
  providedIn: 'root'
})
export class CaptureService {

  private logger: Logger;
  private httpErrorResponse: HttpErrorResponse;
  public workflow: IWorkflow;
  public workflowList: Array<IWorkflow>;
  public imagesArray: Array<any>;
  private processingStatussesArray = [];


  constructor(
    private diagnostic: Diagnostic,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private languageService: LanguageService,
    private loggingService: LoggingService,
    private httpClient: HttpClient
  ) {
    this.logger = loggingService.getLogger("[CaptureService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
   }

  public getWorkflows(workflowsUrl): Observable<any> {
    const urlForMobileWorkflows = workflowsUrl + '?page=0&pageSize=20&direction=ASC&sort=NAME&sources=PHONE'
    return new Observable((observer) => {
      this.httpClient.get<any>(urlForMobileWorkflows)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getWorkflows() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[CaptureService] getWorkflows()');
            observer.error(this.httpErrorResponse);
            observer.complete();
          }
        })
      )))
      .subscribe((response: any) => {
        console.log('response', response);
        observer.next(this.createWorkflowList(response));
        observer.complete();
      });
    });
  }

  private createWorkflowList(response) {
    let workflowList:Array<IWorkflow> = [];
    if (response._embedded && response._embedded['px:workflowResources']) {
      for (let workflowResource of response._embedded['px:workflowResources']) {
        // console.log('workflowResource', workflowResource);
        workflowList.push(this.setWorkflowData(workflowResource));
      }
      // console.log('workflowList', workflowList);
    }
    return workflowList;
  }

  private setWorkflowData(workflowResource) {
    const refactoredWorkflow: IWorkflow = {
      links: {
        groups: null,
        scans: null,
        test: null,
        self: null
      },
      active: workflowResource['active'],
      created: workflowResource['created'] ? workflowResource['created'] : null,
      destinations: workflowResource['destinations'] ? workflowResource['destinations'] : [],
      fileType: this.setFileType(workflowResource),
      imageQuality: this.setImageQuality(workflowResource),
      name: workflowResource['name'] ? workflowResource['name'] : null,
      workflowActivities: this.setWorkflowActivityList(workflowResource)
    }
    if (workflowResource._links) {this.setWorkflowLinks(refactoredWorkflow, workflowResource._links)};
    return refactoredWorkflow;
  }

  private setFileType(workflowResource): string {
    let fileType = null;
    const workflowFileType = workflowResource['_embedded']['workflowActivities'][1]['type'];
    switch (workflowFileType) {
      case 'GENERATE_DOCX':
        fileType = 'MicrosoftWord'
        break;
      case 'GENERATE_SEARCHABLE_PDF':
        fileType = 'PDF';
        break;
    }
    return fileType;
  }

  private setImageQuality(workflowResource): string {
    let imageQuality: string = null;
    switch (workflowResource['outputImageQuality']) {
      case 'MIN':
        imageQuality = IMAGE_QUALITY.MIN;
        break;
      case 'MEDIUM':
        imageQuality = IMAGE_QUALITY.MEDIUM;
        break;
      case 'MAX':
        imageQuality = IMAGE_QUALITY.MAX;
        break;
    }
    return imageQuality;
  }

  private setWorkflowLinks(workflow, links) {
    if (links['px:groups'] && links['px:groups'].href) {
      workflow.links.groups = links['px:groups'].href;
    }
    if (links['px:scans'] && links['px:scans'].href) {
      workflow.links.scans = links['px:scans'].href;
    }
    if (links['px:test'] && links['px:test'].href) {
      workflow.links.test = links['px:test'].href;
    }
    if (links['self'] && links['self'].href) {
      workflow.links.self = links['self'].href;
    }
  }

  private setWorkflowActivityList(workflowResource) {
    let workflowActivityList: Array<IWorkflowActivity> = [];
    if (workflowResource._embedded['workflowActivities']) {
      for (let workflowActivity of workflowResource._embedded['workflowActivities']) {
        workflowActivityList.push(this.setWorkflowActivity(workflowActivity))
      }
    }
    return workflowActivityList;
  }

  private setWorkflowActivity(workflowActivity) {
// console.log('workflowActivity', workflowActivity);
    const activity: IWorkflowActivity = {
      links: {
        self: workflowActivity._links['self'] && workflowActivity._links['self'].href ? workflowActivity._links['self'].href : null,
      },
      stage: workflowActivity['stage'] ? workflowActivity['stage'] : null,
      type: workflowActivity['type'] ? workflowActivity['type'] : null
    }
    return activity;
  }

  // public checkPermissionEnabled(permission): Observable<string> {
  //   this.logger.info('checkPermissionEnabled()');
	// 	return new Observable((observer) => {
  //     this.diagnostic.getPermissionAuthorizationStatus(permission).then((status) => {
  //       console.log('status', status);
  //       switch (status) {
  //         case this.diagnostic.permissionStatus.GRANTED:
  //           console.log("Permission granted to use the gallery");
  //           this.logger.info('checkPermissionEnabled() - Permission granted to use gallery');
  //           break;
  //         case this.diagnostic.permissionStatus.DENIED_ONCE:
  //         case this.diagnostic.permissionStatus.DENIED_ALWAYS:
  //         case this.diagnostic.permissionStatus.NOT_REQUESTED:
  //           this.logger.info('checkPermissionEnabled() - Does not have permission. grant permission to use gallery');
  //           console.log("Give permission to use the Gallery");
  //           break;
  //       }
  //       observer.next(status);
  //       observer.complete();
  //     },(error) => {
  //       console.error("The following error occurred: " + error);
  //       observer.error(error);
  //       observer.complete();
  //     });
  //   });
  // }

  public startCaptureUpload(workflow: IWorkflow, imagesArray: any): Observable<any> {
    let startCaptureUploadUrl = workflow.links.scans;
    let imageCount = imagesArray.length;
    return new Observable((observer) => {
      this.httpClient.post<any>(startCaptureUploadUrl, {inputFileCount: imageCount})
      // this.httpClient.post<any>(startCaptureUploadUrl, {})
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
            if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
              console.log('startCaptureUpload this.httpErrorResponse', this.httpErrorResponse);
              this.logger.info('startCaptureUpload() httpErrorResponse === ' + this.httpErrorResponse.status);
              observer.complete();
            } else {
              observer.error(this.httpErrorResponse);
              observer.complete();
            }
        })
      ))).subscribe((uploadStartedResponse) => {
        console.log('start capture upload response', uploadStartedResponse);
		    let uploadFinishedCount = 0; // also used to set the index for which uploadFile link to use for uploading a specific image file in uploadCaptureToServer()
        let uploadFileIndex = uploadFinishedCount -1;
        imagesArray.forEach(imgObj => {
          console.log('## UPLOAD');
          uploadFileIndex++;
          this.uploadCaptureToServer(uploadStartedResponse, imgObj.imgBlob, imgObj.imgSize, uploadFileIndex)
          .subscribe((response) => {
            console.log('uploadCaptureToServer() response', response);
            uploadFinishedCount++;
            console.log('uploadFinishedCount', uploadFinishedCount);
            console.log('imageCount', imageCount);
            if (uploadFinishedCount === imageCount) {
              console.log('## UPLOAD_IS_FINISHED');
              observer.next(response);
              observer.complete();
            };
          });
        });
      });
    });
  }

  public uploadCaptureToServer(uploadStartedResponse, imageBlob, imageSize, uploadFileIndex): Observable<any> {
    console.log('UPLOAD_CAPTURE_TO_SERVER()');
    console.log('imageBlob', imageBlob);
    console.log('uploadStartedResponse', uploadStartedResponse);
    console.log('uploadFileIndex', uploadFileIndex);
    const uploadLink = uploadStartedResponse['currentActivity']['_embedded']['uploadFiles'][uploadFileIndex]['uploadLink'];
    console.log('uploadLink', uploadLink);
    const uploadHeaders = uploadStartedResponse['currentActivity']['_embedded']['uploadFiles'][uploadFileIndex]['uploadHeaders'];
    console.log('uploadHeaders', uploadHeaders);
    const finishUploadLink = uploadStartedResponse['currentActivity']['_embedded']['uploadFiles'][uploadFileIndex]['_links']['px:finishUploads']['href'];
    console.log('finishUploadLink', finishUploadLink);
    return new Observable((observer) => {
      this.httpClient.put<any>(uploadLink, imageBlob, {headers: uploadHeaders})
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
            observer.complete();
            if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
              console.log('startCaptureUpload this.httpErrorResponse', this.httpErrorResponse);
              this.logger.info('createResourceForLogsOnServer() httpErrorResponse === ' + this.httpErrorResponse.status);
            }  else {
              this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[SendLogsService] createResourceForLogsOnServer()');
            }
        })
      )))
      .subscribe((uploadFinishedResponse) => {
        console.log('upload finised response', uploadFinishedResponse);
        this.confirmUpload(finishUploadLink, imageSize)
        .subscribe((res) => {
          observer.next(res);
          observer.complete();
        });
      });
    })
  }

  private confirmUpload(finishUploadLink, imageSize): Observable<any> {
    console.log('## CONFIRM_UPLOAD');
    const objectPost: any = {
        "pages": 1,
        "fileSize": imageSize,
        "mimeType": "image/jpg"
    }

    return new Observable((observer) => {
      this.httpClient.post<any>(finishUploadLink, objectPost)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('confirmUpload() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.logger.info('confirmUpload() POST ERROR - Object to post: ' + JSON.stringify(objectPost));
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'PATCH', '[sendLogsService] confirmUpload()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        console.log('POST finish-upload response', response);
        observer.next(response);
        observer.complete();
      });
    });
  }

  public getScanUpdatedData(data): Observable<any> {
    // set accept language header: Accept-Language
    const currentLanguage = this.languageService.getCurrentLanguageCode();
    const languageHeader = new HttpHeaders({
      'Accept-Language': currentLanguage
    });
    return new Observable((observer) => {
      this.httpClient.get<any>(data.scan, {headers: languageHeader})
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('confirmUpload() httpErrorResponse === ' + this.httpErrorResponse.status);
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        // console.log('getScanUpdatedData()', response);
        observer.next(response);
        observer.complete();
      });
    })
  }

  public checkFileProcessingStateOnServer(scanUpdated): Observable<any> {
    let processingPending = false;
    if (scanUpdated.state === 'RUNNING') {
      this.processingStatussesArray.push(scanUpdated);
    } else if (scanUpdated.state === 'FINISHED') {

    }
    return new Observable((observer) => {
      observer.next(processingPending);
      observer.complete();
    });
  }
}

// class WorkflowComponent {
//   constructor(workflow, anyInActiveWorkflows){
//     this.anyInActiveWorkflows = anyInActiveWorkflows
//     this.captions = Captions.getCaptions()
//     this.info = workflow
//     this.destination = null
//     this.scannerSettings = this.setScannerSettings()
//     this.fileType = this.setFileType()
//     this.fileName = ''
//     this.id = workflow._links.self.href.split('/').pop()
//     this.state = 'IDLE'
//     this.activities_FLATMAP = []
//     this.activities_ID_MAP = {}
//     this.activities_LOOKUPMAP = {}

//   }
//   setDestination = (destination) => {
//     switch (destination.type) {
//       case 'SEND_TO_EMAIL':
//         return {
//           to: destination.to,
//           cc: destination.cc,
//           bcc: destination.bcc,
//         }
//       case 'SEND_TO_ONEDRIVE':
//       break
//       case 'SEND_TO_SHAREPOINT':
//       break
//     }
//   }
//   setScannerSettings = () => {
//     return this.info._embedded.workflowActivities.reduce((acc, activity) => {
//       if (activity.type === 'SCAN_FILES') {
//         activity['details'] = {
//           pageOrientation: this.captions[ORIENTATION[activity.pageOrientation]] ?? ORIENTATION[activity.pageOrientation],
//           paperSize: this.captions[PAPER_SIZE[activity.paperSize]] ?? PAPER_SIZE[activity.paperSize],
//           color: this.captions[COLOR[activity.color]] ?? COLOR[activity.color],
//           dotsPerInch: activity.dotsPerInch,
//           duplexMode: activity.duplexMode === 'TOP_TO_TOP' ? (this.captions.Yes ?? 'Yes') : (this.captions.No ?? 'No') ,
//         }
//         acc = activity
//       }

//       return acc
//     }, null)
//   }
//   setFileName = (activity) => {
//     return activity.filename
//   }

//   setFileType = () => {
//     return this.info._embedded.workflowActivities.reduce((acc, activity) => {
//       if (activity.type === 'GENERATE_SEARCHABLE_PDF') {
//         activity['details'] = {
//           extension: '.pdf',
//           pdfCompatibility: PDF_COMPATIBILITY[activity.pdfCompatibility],
//           imageQuality: this.captions[IMAGE_QUALITY[activity.imageQuality]] ?? StringUtils.capitalize(IMAGE_QUALITY[activity.imageQuality])
//         }
//         acc = activity
//       }
//       if (activity.type === 'GENERATE_DOCX') {
//         activity['details'] = {
//           extension: '.docx',
//           imageQuality: this.captions[IMAGE_QUALITY[activity.imageQuality]] ?? StringUtils.capitalize(IMAGE_QUALITY[activity.imageQuality])
//         }
//         acc = activity
//       }
//       return acc
//     }, null)
//   }
//   activityMapping = () => {
//     this.activities_FLATMAP = this.info._embedded.workflowActivities.map((activity) => activity.type);
//     this.info._embedded.workflowActivities.forEach((activity, index) => {
//       const ID = activity._links.self.href.split('/').pop()
//       const activityComponent  = ActivityComponent.getComponent(activity, index, this)
//       this.activities_ID_MAP[ID] = activityComponent.type
//       this.activities_LOOKUPMAP[activityComponent.type] = activityComponent
//     })
//   }
//   template = (_index) => {
//     const destinatioActivity = this.info._embedded.workflowActivities.find((activity) => activity.stage === 'DISTRIBUTING')
//     let icon_variable = ''
//     if (destinatioActivity.type === 'SEND_TO_EMAIL') {
//       icon_variable = 'SEND_TO_EMAIL'
//     } else {
//       icon_variable = destinatioActivity._embedded.destinationProfile.type
//     }
//     const active = this.info.active ? (this.captions.Yes ?? 'Yes') : (this.captions.No ?? 'No');

//     return `button class="list__button pl50 relative icon_${icon_variable}" data-cy="${this.info.name}" wfcid="${this.id}" onclick="ListComponent.setSelectListItem('${this.id}')"
//         div class="no-click "
//           div class="list-name ellipsis"
//             b${this.info.name}/b
//             div class="list-info ${this.anyInActiveWorkflows ? '': 'hide'}"Active:
//               span${active}/span
//             /div
//           /div
//         /div
//       /button`
//     /*
//     return `button class="list__button " onclick="ListComponent.setSelectListItem('${this.id}')"
//         div class="no-click"
//           div class="list-name"b${this.info.name}/b/div
//           div class="list-info"
//             ${this.templateScannerSettings()}
//             ${this.templateFileType()}
//             ${this.templateDestination()}
//             ${this.templateInActive()}
//           /div
//         /div
//       /button`
//     */
//   }
//   templateScannerSettings = () => {
//     const scanFiles = `
//       div
//         <!-- <b>${this.captions.ScanSettings ?? 'ScanSettings'}:/b -->
//           b${this.captions.Orientation}: /b${this.captions[ORIENTATION[this.scannerSettings.pageOrientation]]},
//           b${this.captions.PaperSize}: /b${this.captions[PAPER_SIZE[this.scannerSettings.paperSize]] ?? PAPER_SIZE[this.scannerSettings.paperSize]},
//           b${this.captions.Color}: /b${this.captions[COLOR[this.scannerSettings.color]] === 'Color' ? (this.captions.Yes ?? 'Yes') : this.captions[COLOR[this.scannerSettings.color]]},
//           b${this.captions.Resolution}: /b${this.scannerSettings.dotsPerInch},
//           b${this.captions.SupportDuplex ?? 'SupportDuplex'}: /b${this.scannerSettings.duplexMode === 'TOP_TO_TOP' ? (this.captions.Yes ?? 'Yes') : (this.captions.No ?? 'No')}
//       /div`
//     return scanFiles
//   }
//   templateFileType = () => {
//     if (this.fileType.type === 'GENERATE_SEARCHABLE_PDF') {
//       const pdfFileType = `
//         div
//           b${this.captions.FileType}: /b ${PDF_COMPATIBILITY[this.fileType.pdfCompatibility]}
//           b${this.captions.PdfImageQuality ?? 'PdfImageQuality'}: /b
//             ${this.fileType.details.imageQuality}
//         /div`
//       return pdfFileType
//     } else if (fileType.type === 'GENERATE_DOCX') {
//       const docxFileType = `
//         div
//           b${this.captions.FileType}: /b
//           docx
//         /div`
//       return docxFileType
//     }
//   }
//   templateDestination = () => {
//     const destination = `
//       div
//         b${this.captions.Destination}: /b${this.captions[this.destination.type]}
//       /div`
//     return destination
//   }